// -----------------------------------------------------------------------------
export class CookieUtils {
// -----------------------------------------------------------------------------
  public static getCookie(name: string): string {
    const dc = document.cookie;
    const prefix = name + '=';
    let begin = dc.indexOf('; ' + prefix);
    let end: number;
    if (begin == -1) {
      begin = dc.indexOf(prefix);
      const indexOfEnd = document.cookie.indexOf(';', begin);
      end = indexOfEnd === -1 ? undefined : indexOfEnd;
      if (begin != 0) return null;
    } else {
      begin += 2;
      end = document.cookie.indexOf(';', begin);
      if (end == -1) {
        end = dc.length;
      }
    }

    return decodeURI(dc.substring(begin + prefix.length, end));
  }

  // ---------------------------------------------------------------------------
  public static deleteCookie(name: string): void {
    document.cookie = name + '=; Max-Age=0; path=/;';
  }
}
