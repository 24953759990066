export const kImageFolderUrl = '/static_next/img';
export const kAssetSpriteUrl = `${kImageFolderUrl}/sprite.svg`;
export const kReviewsPageSize = 50;
export const kDefaultMetaKeywords = ['In game communication', 'League of Legends', 'Overwatch', 'Skype', 'Games', 'Facebook for games', 'IM for games', 'in game technology', 'Overwolf', 'game chat', 'in game chat'];
export const kBackgroundImgFallBack = `${kImageFolderUrl}/app-page/hero-placeholder.webp`;
export const kAboutTheCreatorBackgroundImgFallBack = `${kImageFolderUrl}/app-page/placeholder-creator.webp`;

// export const kdlListUITest = [
//   {
//     platform: 'Windows',
//     label: 'Download on Overwolf',
//     appId: 'lonckeekdnefdmfphggfaijaffmdcdfgoinncnkg',
//   },
//   {
//     platform: 'Mac',
//     label: 'Download on Mac',
//     url: 'https://curseforge.overwolf.com/downloads/curseforge-latest.dmg',
//   },
//   {
//     platform: 'Linux',
//     label: 'Download Standalone',
//     url: 'https://curseforge.overwolf.com/downloads/curseforge-latest-linux.deb',
//   },
//   {
//     platform: 'Linux',
//     label: 'Download Standalone',
//     url: 'https://curseforge.overwolf.com/downloads/curseforge-latest-linux.zip',
//   },
//   {
//     platform: 'Windows',
//     label: 'Download Standalone',
//     appId: 'mgkabooemhaamambocobpeoeelpadcjhjgbcfhlc',
//   },
// ];