import { useContext } from 'react';
import GlobalContext from '../context/global-context';
import Utils from '../utils/utils';

interface Param {
  channel?: string;
  name?: string;
  extensionId?: string;
  utmContent?: string;
}

export const useBuildDownloadUrl = (param: Param): string => {
  const { config } = useContext(GlobalContext);
  const downloadBaseUrl  = config?.publicRuntimeConfig?.base?.downloadBaseUrl;
  const downloadUrlPath = '/install/Download';
  const url = new URL(`${downloadBaseUrl}${downloadUrlPath}`);

  if (param.channel) {
    url.searchParams.append('Channel', param.channel);
  }

  if (param.name) {
    url.searchParams?.append('Name', Utils.sanitizeString(param.name));
  }

  if (param.extensionId) {
    url.searchParams?.append('ExtensionId', param.extensionId);
  }

  if (param.utmContent) {
    url.searchParams?.append('utm_content', Utils.sanitizeString(param.utmContent));
  }

  return url.toString();
};
