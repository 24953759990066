import { CookieUtils } from './cookie-utils';
import Utils from './utils';

const URL_PARAMS = [
  'PartnerId',
  'Channel',
  'ExtensionId',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'extra',
  'rafToken',
  'gclid',
];

const URL_ONLY_PARAMS = ['PartnerId'];

// -----------------------------------------------------------------------------
export class UrlUtils {
  // ---------------------------------------------------------------------------
  public static appendTrackingParams(url: string): string {
    if (!url) {
      return url;
    }

    url = Utils.appendHTTPSIfMissing(url);

    const newURL = new URL(url);
    for (const param of URL_PARAMS) {
      let value;

      if (URL_ONLY_PARAMS.includes(param)) {
        value = this.getUrlParameter(param);
      } else {
        value = this.getParameter(param);
      }

      if (!value) {
        continue;
      }

      newURL.searchParams.set(param, value);
    }

    return newURL.toString();
  }

  // ---------------------------------------------------------------------------
  public static getDownloadUrlForCampaign(trackingParams): string {
    return (
      window as any
    ).OW?.downloadCampaignTracking.getDownloadUrlForCampaign(trackingParams);
  }

  // ---------------------------------------------------------------------------
  private static getUrlParameter(name, defaultValue = ''): string {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);
    return results === null
      ? defaultValue
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  // ---------------------------------------------------------------------------
  private static getParameter(name, defaultValue = null): string {
    let value = CookieUtils.getCookie(name);
    if (!value) {
      value = defaultValue;
    } else {
      value = encodeURIComponent(value);
    }

    return value;
  }
}
